import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { settings } from './const';
import * as Styled from './styles';
import { BannerCarouselProps } from './types';

import Image from '../../atoms/Image';
import { numberToTwoDigits } from '../../../utils/consts';

const BannerCarousel: React.FC<BannerCarouselProps> = ({ items }) => (
  <Styled.BannerCarousel>
    <Slider {...settings}>
      {items.map(({ key, title, desc, image, mobileImage }, index: number) => (
        <Styled.BannerCarouselSlide {...{ key }}>
          <Styled.BannerCarouselMedia hasMobileImage={!!mobileImage}>
            <Image {...image} />
            {mobileImage && <Image {...mobileImage} />}
          </Styled.BannerCarouselMedia>
          <Styled.BannerCarouselContent>
            <Styled.BannerCarouselCounter>
              <Styled.BannerCarouselCounterCurrent variant="headingS">
                {numberToTwoDigits(index + 1)}
              </Styled.BannerCarouselCounterCurrent>
              <Styled.BannerCarouselCounterAll variant="textL">
                {numberToTwoDigits(items.length)}
              </Styled.BannerCarouselCounterAll>
            </Styled.BannerCarouselCounter>
            <Styled.BannerCarouselInfo>
              <Styled.BannerCarouselTitle variant="textL">{title}</Styled.BannerCarouselTitle>
              <Styled.BannerCarouselDesc variant="textS">{desc}</Styled.BannerCarouselDesc>
            </Styled.BannerCarouselInfo>
          </Styled.BannerCarouselContent>
        </Styled.BannerCarouselSlide>
      ))}
    </Slider>
  </Styled.BannerCarousel>
);

export default BannerCarousel;
