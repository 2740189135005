import { graphql } from 'gatsby';
import React from 'react';

import { getImage } from '../../../../utils/sanityTypes';
import { BannerCarouselItemProps } from '../../../organisms/BannerCarousel/types';
import WideBanner from '../../WideBanner';
import { WideBannerSectionProps } from './types';

const WideBannerSection: React.VFC<WideBannerSectionProps> = ({ buttonLabel, slides }) => (
  <WideBanner
    data={{
      buttonLabel: buttonLabel ?? '',
      items: (slides ?? []).map<BannerCarouselItemProps>((slide) => {
        const { _key: key, title, desc, image, mobileImage } = slide ?? {};

        return {
          key: key ?? '',
          title: title ?? '',
          desc: desc ?? '',
          image: getImage(image),
          mobileImage: getImage(mobileImage),
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment WideBannerSectionFragment on SanityWideBannerSection {
    _key
    _type
    buttonLabel
    slides {
      _key
      title
      desc
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default WideBannerSection;
