import React, { useState } from 'react';

import * as Styled from './styles';
import { WideBannerProps } from './types';

import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Modal from '../../molecules/Modal';
import BannerCarousel from '../../organisms/BannerCarousel';

const WideBanner: React.FC<WideBannerProps> = ({ data }) => {
  const { buttonLabel, items } = data;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styled.WideBanner>
      <Styled.WideBannerMedia>
        <Styled.WideBannerOverlay />
        <Styled.WideBannerImage isMobileHidden={items[0].mobileImage != null}>

          <Image {...items[0].image} />

        </Styled.WideBannerImage>

        {items[0].mobileImage &&
          <Styled.WideBannerImageMobile>
            <Image {...items[0].mobileImage} />
          </Styled.WideBannerImageMobile>
        }

      </Styled.WideBannerMedia>
      <Styled.WideBannerInner>
        <Button hasOverlayMask onClick={() => setIsOpen(!isOpen)}>
          {buttonLabel}
        </Button>
      </Styled.WideBannerInner>
      <Modal {...{ isOpen }} onClose={() => setIsOpen(!isOpen)} isFullWindow>
        <BannerCarousel {...{ items }} />
      </Modal>
    </Styled.WideBanner>
  );
};

export default WideBanner;
