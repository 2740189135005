import styled, { css } from 'styled-components';

import media from '../../../common/MediaQueries';
import { FlexBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import { CarouselPaginationDots } from '../../atoms/Carousel/styles';
import { ImageStyled } from '../../atoms/Image/styles';
import { BannerCarouselMediaStyledProps } from './types';

export const BannerCarousel = styled(FlexBox)`
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    bottom: 0rem;
    z-index: 1;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1.75rem;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    right: 2.25rem;
  }

  ${CarouselPaginationDots} {
    position: absolute;
    bottom: 0.5rem;
  }

  @media ${media.maxTablet} {
    .slick-slider {
      padding-bottom: 2rem;
    }
    .slick-list {
      max-height: calc(100vh - 7rem);
    }
  }

  @media ${media.tablet} {
    .slick-slider {
      max-height: 43.75rem;
    }
    .slick-arrow {
      bottom: 1.625rem;
    }
    ${CarouselPaginationDots} {
      bottom: 2.125rem;
    }
  }
`;

export const BannerCarouselSlide = styled.div`
  height: 100%;
`;

export const BannerCarouselMedia = styled.div<BannerCarouselMediaStyledProps>`
  height: calc(100% - 7.5rem);

  ${ImageStyled}:last-of-type {
    display: none;
  }

  ${({ hasMobileImage }) =>
    hasMobileImage &&
    css`
      @media ${media.maxPhone} {
        ${ImageStyled}:first-of-type {
          display: none;
        }
        ${ImageStyled}:last-of-type {
          display: block;
        }
      }
    `}
`;

export const BannerCarouselContent = styled(FlexBox)`
  padding-top: 1rem;
  height: 7.5rem;

  @media ${media.phone} {
    align-items: center;
    padding-top: 2.5rem;
  }
`;

export const BannerCarouselCounter = styled(FlexBox)`
  position: relative;
  align-items: center;
  height: 2.5rem;
  margin-right: 1rem;

  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 1px;
    left: 2.25rem;
    transform: skewX(-14deg);
    background-color: ${({ theme }) => theme.colors.neutral.neutral100};
  }

  @media ${media.phone} {
    margin-right: 2.5rem;
  }
`;

export const BannerCarouselCounterCurrent = styled(Typography)`
  width: 1.75rem;
  margin-right: 0.875rem;
`;

export const BannerCarouselCounterAll = styled(Typography)`
  width: 1.75rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const BannerCarouselInfo = styled.div`
  max-width: 16.5rem;
  @media ${media.maxPhone} {
    min-height: 6.5rem;
  }
`;

export const BannerCarouselTitle = styled(Typography)``;

export const BannerCarouselDesc = styled(Typography)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
