import styled, { css } from 'styled-components';

import { WideBannerImageProps } from './types';

import media from '../../../common/MediaQueries';
import { Inner } from '../../styles/Grid/styles';
import { transition, positionFullStyles } from '../../../common/mixins';

export const wideBannerImageStyles = css`
  ${transition('all', '320ms')}
  height: 100%;
`;

export const WideBannerInner = styled(Inner)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const WideBannerMedia = styled.div`
  ${positionFullStyles}
`;

export const WideBannerImage = styled.div<WideBannerImageProps>`
  ${wideBannerImageStyles}

  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      @media ${media.maxPhone} {
        display: none;
      }
    `}
`;

export const WideBannerImageMobile = styled.div`
  ${wideBannerImageStyles}

  @media ${media.phone} {
    display: none;
  }
`;

export const WideBannerOverlay = styled.div`
  ${positionFullStyles}
  z-index: 1;
  ${({ theme }) => theme.overlays.negativeRadial30}
`;

export const WideBanner = styled.div`
  position: relative;
  display: flex;
  height: ${({ theme }) => theme.heights.mobileWindow};
  overflow: hidden;

  @media ${media.phone} {
    &:hover ${WideBannerImage}, &:hover ${WideBannerImageMobile} {
      transform: scale(1.1);
    }
  }
  @media ${media.tablet} {
    height: 100vh;
  }
`;
