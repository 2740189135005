import React, { PropsWithChildren } from 'react';
import { AnimatePresence } from 'framer-motion';

import { IconCloseCircle } from '../../../assets/svg';

import {
  StyledModal,
  StyledModalClose,
  StyledModalCloseWrapper,
  StyledModalContent,
  StyledModalContentMain,
} from './styles';
import { ModalProps } from './types';

const Modal = React.forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>(
  ({ children, onClose, isOpen, isFullWindow }, ref) => (
    <AnimatePresence>
      {isOpen && (
        <StyledModal
          {...{ isOpen }}
          key="modal"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              delayChildren: 0.2,
            },
          }}
          exit={{
            opacity: 0,
          }}
          transition={{ duration: 0.2, type: 'tween' }}
          {...{ isFullWindow }}
        >
          <StyledModalContent
            key="modal-content"
            initial={{ y: isFullWindow ? '0%' : '20%' }}
            animate={{
              y: 0,
            }}
            exit={{ y: isFullWindow ? '0%' : '20%' }}
            transition={{ duration: 0.2, type: 'tween' }}
            id="modal-content"
            {...{ ref }}
          >
            <StyledModalCloseWrapper>
              <StyledModalClose type="button" onClick={onClose} aria-label="Close Modal Button">
                <IconCloseCircle />
              </StyledModalClose>
            </StyledModalCloseWrapper>
            <StyledModalContentMain>{children}</StyledModalContentMain>
          </StyledModalContent>
        </StyledModal>
      )}
    </AnimatePresence>
  )
);

export default Modal;
