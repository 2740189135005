import styled, { css } from 'styled-components';
import { m } from 'framer-motion';
import { rgba } from 'polished';

import { StyledModalProps } from './types';

import { transition } from '../../../common/mixins';
import { FlexBox, innerStyles } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';

export const StyledModalContent = styled(m.div)`
  margin: 0 auto;
  width: 100%;
  max-width: 51rem;
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.maxTablet} {
    padding: 2rem;
  }
  @media ${media.tablet} {
    padding: 2.5rem;
  }
`;

export const StyledModalContentMain = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const StyledModalCloseWrapper = styled(FlexBox)`
  position: absolute;
  right: 2rem;
  align-items: center;
  justify-content: flex-end;

  @media ${media.tablet} {
    right: 2.5rem;
  }
`;

export const StyledModalClose = styled.button`
  ${transition('color')}
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
  border-radius: 100%;
  border: 0;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.additional.dark};

  @media ${media.tablet} {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary.hover};
    }
  }
`;

export const StyledModal = styled(m.div)<StyledModalProps>`
  ${transition()}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  overflow: auto;
  background-color: ${({ theme }) => rgba(theme.colors.additional.dark, 0.75)};
  z-index: ${({ theme }) => theme.zIndexes.modal};

  @media ${media.phone} {
    padding: 4rem 1rem 1rem;
  }
  @media ${media.tablet} {
    padding: 6rem 2rem 2rem;
  }

  ${({ isFullWindow }) =>
    isFullWindow &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.neutral10};
      ${StyledModalCloseWrapper} {
        right: 1.5rem;
      }
      ${StyledModalClose} {
        width: 1.75rem;
        height: 1.75rem;
        font-size: 1.75rem;
      }
      ${StyledModalContent} {
        max-width: 100%;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
      ${StyledModalContentMain} {
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        ${innerStyles}
      }
      @media ${media.maxTablet} {
        padding: 0;
        ${StyledModalContent} {
          padding: 0;
        }
        ${StyledModalContentMain} {
          max-height: 100vh;
          padding-top: 3rem;
          overflow: auto;
        }
        ${StyledModalCloseWrapper} {
          top: 0.625rem;
        }
      }
      @media ${media.tablet} {
        display: flex;
        align-items: center;
        padding: 0;
        ${StyledModalContent} {
          max-width: 85.25rem;
          max-height: 49rem;
        }
      }
    `}
`;
